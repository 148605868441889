.bm-menu-wrap .bm-menu {
    background-color: #e6e6e6;
}

.bm-menu-wrap .bm-menu .bm-item:focus {
    outline: none;
}

.table-responsive {
    overflow: auto !important;
    max-height: calc(100% - 15px);
}

.detail-table thead th {
    position: sticky;
    top: 0;
    background: #e2e2e2;
    text-align: center;
    z-index: 100;
}

.detail-table tbody td {
    text-align: center;
}

._loading_overlay_wrapper {
    height : 100%;
}

.fenologicos .table-responsive {
    overflow: auto;
    max-height: 400px !important;
}

.fenologicos-table thead th {
    position: sticky;
    top: 0;
    background: #e2e2e2;
    text-align: center;
}


.fenologicos-table tbody td {
    text-align: center;
}

.modal {
    z-index: 1050 !important;
}

.field-row {
    background: #66d0be;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.polygon-row:hover {
    background-color: #eeeeee;
}

.polygon-name-label {
    font-size: 26px !important;
    font-weight: bold;
    margin-bottom: 0;
    cursor: pointer;
    color: #51cbce !important;
    transition: 0.2s;
}

.polygon-name-label:hover {
    color : #6bd098 !important;
    transition: 0.2s;
}

