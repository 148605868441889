.leaflet-control-measure {
    font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

.leaflet-control-measure {
    border-radius: 12px;
}

.leaflet-control-measure .startprompt h3 {
    color: #51cbce;
}

.leaflet-control-measure h3, .leaflet-measure-resultpopup h3 {
    color: #51cbce;
}

.leaflet-control-measure .results .heading {
    color: #70b17d;
}
.leaflet-container a.leaflet-popup-close-button {
    color: #e85d5d;
}